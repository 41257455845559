import axios from "axios";
var sha512 = require('js-sha512');

class MemberService {
  constructor() {
    if (!MemberService.instance) { MemberService.instance = this }
    return MemberService.instance
  }

  defaultAuthConfig = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    return { headers: { Authorization: `Bearer ${accessToken}` } }
  }

  // defaultAutConfig = () => {
  //   const accessToken = sessionStorage.getItem("accessToken");
  //   return { headers: { Authorization: `Bearer ${accessToken}` + 'Token' } }
  // }

  getBaseUrl = () => {return process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_BASE_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_BASE_URL :  process.env.REACT_APP_BASE_URL_STAGE;  }
  
  
  
  handleLogin = (data, credentialType, otp) => {
    const headers = {
      "Content-Type": "application/xml",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    };
    let request = {
      "credentialType": credentialType,
      "credentials": {
        "username": data.userName,
        "hashedPassword": otp !== null ? sha512(otp) : sha512(data.password)
      }
    }
    let url = `${this.getBaseUrl()}/members/sign-in`;
    return axios
      .post(url, request, { headers: headers, ignoreDefaultAuth: true })
  };

  handleLoginViaMobile = (mobileNumber, credentialType, otp) => {
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    };
    let request = {
      "credentialType": "MobileNumber",
      "credentials": {
        "mobileDetails": {
          "mobile": {
            "mobileNumber": mobileNumber,
            "countryCode": "+60"
          },
          "otp": otp,
       //   "bypassCode": "123456"
        }
      }
    }
    let url = `${this.getBaseUrl()}/members/sign-in`;
    return axios
      .post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }


  handleMemberProfile = (userId) => {

    const url = `${this.getBaseUrl()}/members/${userId}/profile`;
    const config = { ...this.defaultAuthConfig() }
    return axios.get(url, config);
  }

  handleVehicleJPJInfo = (data) => {
    const url = `${this.getBaseUrl()}/bike/add-vehicle-jpj-info`;
    const config = { ...this.defaultAuthConfig() }
    return axios.post(url, data, config)
  }

    deleteVehicle = (data) => {
    const url = `${this.getBaseUrl()}/bike`;    
    const config = { ...this.defaultAuthConfig() , params:{...data}}
    return axios.delete(url, config )
  }


  fetchVehicleModels = () => {
    const headers = {
      "Content-Type": "application/xml",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    };
    const url = `${this.getBaseUrl()}/bike/models`;
    const config = { ...this.defaultAuthConfig() }
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  handleSignUp = (data) => {
    const headers = {
      "Content-Type": "application/xml",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    };
    let request = {
      username: data.userName,
      hashedPassword: sha512(data.password)
    }
    const url = `${this.getBaseUrl()}/members/sign-up`;
    return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }

  // bike/6dc66091-31c3-4abd-81ad-d2362da1f6b4/add-vehicle-jpj-info
  updateVehicleJPJInfo = (data, vehicleId) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      // "x-api-key":"4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45",
      "Authorization": `Bearer ${accessToken}`
    };
    const url = `${this.getBaseUrl()}/bike/${vehicleId}/add-vehicle-jpj-info`;
    return axios.put(url, data, { headers: headers, ignoreDefaultAuth: true })
  }

  fetchs3PresignedURl = (fileName) => {
    const url = `${this.getBaseUrl()}/bike/add-vehicle-jpj-info/pre-signed-url?fileName=${fileName}`;
    return axios.get(url);

  }
  fetchs3PresignedURlForProfile = (fileName) => {
     const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      // "Content-Type": "application/xml",
      // "x-api-key":"4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45",
      "Authorization": `Bearer ${accessToken}`
    };
    const url = `${this.getBaseUrl()}/presigned-url/profile-image?fileName=${fileName}`;
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });

  }

  updateSupportTicket = (data) => {
    
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      // "x-api-key":"4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45",
      "Authorization": `Bearer ${accessToken}`
    };
    const url = `${this.getBaseUrl()}/integrations/freshdesk-integrations`;
    return axios.post(url, data, { headers: headers, ignoreDefaultAuth: true })
  }

  fetchs3PresignedURlForSupport = (fileName) => {
    const accessToken = sessionStorage.getItem("accessToken");
   const headers = {
     // "Content-Type": "application/xml",
     // "x-api-key":"4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45",
     "Authorization": `Bearer ${accessToken}`
   };
   const url = `${this.getBaseUrl()}/presigned-url/support-images?fileName=${fileName}`;
   return axios.get(url, { headers: headers, ignoreDefaultAuth: true });

 }

  uploadToS3 = (res, files) => {
    const url = res.data.presignedUrl;
    return axios.put(url, files, {
      headers: {
        'Content-Type': "application/octet-stream"
      }
    })
  }


  handleSendOtp = (mobileNumber) => {
    var indexPlus =  mobileNumber.indexOf("+") ;
    if(indexPlus  === -1)
    {
      mobileNumber = "%2B"+mobileNumber;
    }
    else
    {
      var temp = mobileNumber.slice(1);
      mobileNumber = "%2B"+temp;

    }
    const url = `${this.getBaseUrl()}/otp?emailOrMobile=${mobileNumber}&verificationType=MobileNumber`;
    const headers = {
      "Content-Type": "application/xml",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }


  getStateAndCity = (postCode) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    const url = `${this.getBaseUrl()}/city-state?postCode=${postCode}`;
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  updateMemberProfileDetails = (data, userId) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    const url = `${this.getBaseUrl()}/members/${userId}/profile`;
    return axios.put(url, data, { headers: headers, ignoreDefaultAuth: true })
  }



  extendMembership = (userId) => {
   var memberId =  userId ;
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    const url = `${this.getBaseUrl()}/members/${userId}/manage-membership`;
    return axios.put(url, {userId} , { headers: headers, ignoreDefaultAuth: true })
  }



  handleResetPassword = (data, mobileNumber) => {
    let request = {
      "mobile": {
        "mobileNumber": data.mobileNumber || mobileNumber,
        "countryCode": "+60"
      },
      "newPassword": sha512(data.password)
    }
    const url = `${this.getBaseUrl()}/members/password/forgot`;
    const headers = {
      "Content-Type": "application/xml",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    };
    return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }

  createMembershipNumber = (userId) =>{
    
  }


  redirectGOS = (data) => {
    console.log(data)
    const url = `${this.getBaseUrl()}/auth/gos-sign-in`;
    const headers = {
      "Content-Type": "application/xml",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    };
    return axios.post(url, data, { headers: headers, ignoreDefaultAuth: true })
  }

  createDefaultCard = (userId) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    let request = {

    }
    const url = `${this.getBaseUrl()}/members/${userId}/cards`;
    return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }


  fetchMemberPoints = (userId) => {
    const url = `${this.getBaseUrl()}/members/${userId}/points-balance`;
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  fetchMemberNotifications = (userId) => {
    const url = `${this.getBaseUrl()}/members/${userId}/notifications`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  markNotificationsAsRead = (userId, notificationId) => {
    const url = `${this.getBaseUrl()}/members/${userId}/notifications/${notificationId}`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    return axios.put(url, {}, { headers: headers, ignoreDefaultAuth: true })
  }

  getProductCategories = () => {
    const url = `${this.getBaseUrl()}/genblu/products/categories`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true })
  }

  getFilteredGenbluProducts = (filterArray) => {
    const url = `${this.getBaseUrl()}/genblu/products/categories/filter`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    let request = {filterArray}
    return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }
  

  handlContinueAsFan = () => {
    const url = `${this.getBaseUrl()}/bike/removeAll`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    let request = {}
    return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }
  

  getTransactionList = (userId) => {
    const url = `${this.getBaseUrl()}/members/${userId}/transactions?transactionType=all`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  getOrderDetails = (transactionId) =>{
    const url = `${this.getBaseUrl()}/genblu/orders/${transactionId}`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  getMultipleCampaigns = () => {
    const url = `${this.getBaseUrl()}/campaigns?campaignType=Live`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  getSpecificCampaigns = (campaignId) => {
    const url = `${this.getBaseUrl()}/campaigns/${campaignId}`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });

  }

  getMemberPointsBalance = (memberId, queryParam) => {
    let query={
      fetchLimit:10000,
      paginationBookmark:0
    }
    const url = `${this.getBaseUrl()}/members/${memberId}/points-balance`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
   
    return axios.get(url, {params:query, headers: headers, ignoreDefaultAuth: true });
  }

  getSpecificTransactions = (memberId,transactionId) => {
    const url = `${this.getBaseUrl()}/members/${memberId}/transactions/${transactionId}`;
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
   
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  fetchAllCoupons = (memberId) => {
    const url = `${this.getBaseUrl()}/members/${memberId}/e-service-coupons`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  fetchAllVouchers = (memberId) => {
    const url = `${this.getBaseUrl()}/members/${memberId}/vouchers`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  fetchDetailsForVoucher = (voucherId, memberId) => {
    const url = `${this.getBaseUrl()}/members/${memberId}/vouchers/${voucherId}`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  fetchDetailsForCoupon = (couponId, memberId) => {
    const url = `${this.getBaseUrl()}/members/${memberId}/${couponId}`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });

  }


  

  getGenbluProducts = () => {
    const url = `${this.getBaseUrl()}/genblu/products`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  addGenbluProducts = (request) =>{
    const url = `${this.getBaseUrl()}/genblu/orders`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }

  addProductToWishlist = (productId) => {
    const url = `${this.getBaseUrl()}/genblu/products/${productId}/add-to-wishlist`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    let request = {}
    return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }

  fetchWishlistedProducts = () => {
    const url = `${this.getBaseUrl()}/genblu/products/wishlist`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  removeFromWishlist = (productId) => {
    const url = `${this.getBaseUrl()}/genblu/products/${productId}/remove-from-wishlist`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    let request = {}
    return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }

  fetchProductsInCart = ()=>{
    const url = `${this.getBaseUrl()}/genblu/products/cart`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }



  fetchSubscriptionPopupData = () => {
    const url = `${this.getBaseUrl()}/subscriptions/rules/sign-up`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
    
  }
  
  fetchSellerId = () => {
    var memberId = sessionStorage.getItem("userId");
    const url = `${this.getBaseUrl()}/members/${memberId}/manage-membership`

    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
    return axios.put(url, {} ,  { headers: headers, ignoreDefaultAuth: true });
  }

  fetchReactivationData = () => {
    const url = `${this.getBaseUrl()}/subscriptions/reactivate`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
    
  }
  

  addProductsToBag =(productId,request) =>{
    const url = `${this.getBaseUrl()}/genblu/products/${productId}/add-to-cart`
     const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`
    };
    return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }


  removeProductsFromCart=(productId , variationId)=>{
    console.log(productId , variationId);
    const url = `${this.getBaseUrl()}/genblu/products/${productId}/remove-from-cart`
    const accessToken = sessionStorage.getItem("accessToken");
   const headers = {
     "Content-Type": "application/xml",
     "Authorization": `Bearer ${accessToken}`
   };
   let request = {variationId : variationId}
   return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
    // {{baseUrl}}/genblu/products/6523f5ad-c67c-46e1-81db-75a7206897f4/remove-from-cart
  }

  getEventsAttended =(memberId)=>{
    const url = `${this.getBaseUrl()}/members/${memberId}/events/statistics`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }


  handleChangePassword = (data, mobileNumber) => {
    let request = {
      "mobile": {
        "mobileNumber": data.mobileNumber || mobileNumber,
        "countryCode": "+60"
      },
      "oldPassword":sha512(data.oldPassword),
      "newPassword": sha512(data.password)
    }
    const url = `${this.getBaseUrl()}/members/password/change`;
    const headers = {
      "Content-Type": "application/xml",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    };
    return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }


  setUserName=(memberId,data)=>{
    let request ={
      "username":data.userName,
    "hashedPassword":sha512(data.password)
    }
    const url = `${this.getBaseUrl()}/members/${memberId}/username`
    const accessToken = sessionStorage.getItem("accessToken");
   const headers = {
     "Content-Type": "application/xml",
     "Authorization": `Bearer ${accessToken}`
   };
   return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }

  editUserName=(memberId,request)=>{
    const url = `${this.getBaseUrl()}/members/${memberId}/username`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      // "x-api-key":"4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45",
      "Authorization": `Bearer ${accessToken}`
    };
    return axios.put(url, request, { headers: headers, ignoreDefaultAuth: true })
  }


  getMembershipDetails=(memberId)=>{
    // {{baseUrl}}/members/b745d627-f4a3-4419-bb5a-1769316e6739/manage-membership
    const url = `${this.getBaseUrl()}/members/${memberId}/manage-membership`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      "Authorization": `Bearer ${accessToken}`,

    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  setDefaultMembershipCard=(memberId,membershipCardId) => {
    const url = `${this.getBaseUrl()}/members/${memberId}/cards/${membershipCardId}`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/xml",
      // "x-api-key":"4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45",
      "Authorization": `Bearer ${accessToken}`
    };
    return axios.put(url, {}, { headers: headers, ignoreDefaultAuth: true })
  }


  removeBikeAssignee =(bikeId)=>{
    const url = `${this.getBaseUrl()}/bike/${bikeId}/remove-assignee`
    const accessToken = sessionStorage.getItem("accessToken");
   const headers = {
     "Content-Type": "application/xml",
     "Authorization": `Bearer ${accessToken}`
   };
   return axios.post(url, {}, { headers: headers, ignoreDefaultAuth: true })
  }


  addBikeConfirmAssignee =(bikeId,request)=>{
    const url = `${this.getBaseUrl()}/bike/${bikeId}/confirm-assign`
    const accessToken = sessionStorage.getItem("accessToken");
   const headers = {
     "Content-Type": "application/xml",
     "Authorization": `Bearer ${accessToken}`
   };
   return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }

  addBikeAssignee =(bikeId,request)=>{
    const url = `${this.getBaseUrl()}/bike/${bikeId}/assign`
    const accessToken = sessionStorage.getItem("accessToken");
   const headers = {
     "Content-Type": "application/xml",
     "Authorization": `Bearer ${accessToken}`
   };
   return axios.post(url, request, { headers: headers, ignoreDefaultAuth: true })
  }


  verifyOTP =(memberId,request)=>{
    const url = `${this.getBaseUrl()}/members/${memberId}/verify-otp`
    const accessToken = sessionStorage.getItem("accessToken");
   const headers = {
     "Content-Type": "application/json",
     "Authorization": `Bearer ${accessToken}`
   };
   return axios.post(url,request, { headers: headers, ignoreDefaultAuth: true })
  }
  // {{baseUrl}}/otp?emailOrMobile=nikhil.garg@mba.christuniversity.in&verificationType=EmailAddress&bypassCode=123456


  verifyEnteredOTP = (request)=>{
    const url = `${this.getBaseUrl()}/members/otp/verify`
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    };
    return axios.post(url,request, { headers: headers, ignoreDefaultAuth: true })
  }

  getEmailVerificationLink = (memberId,request) => {
    const url = `${this.getBaseUrl()}/otp`
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    };
    return axios.get(url, { params:request, headers: headers, ignoreDefaultAuth: true });
  }

  getBankList = () =>{
    const url = `${this.getBaseUrl()}/payment/bank-list-enquiry`
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    };
    return axios.get(url, { headers: headers, ignoreDefaultAuth: true });
  }

  fpxPayment = (request) => {
    const url = `${this.getBaseUrl()}/payment/fpx-sign`
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    };
    return axios.post(url,request, { headers: headers, ignoreDefaultAuth: true })
  }

  getZeroAmountSubscriptionFee = (userId) => {
    const url = `${this.getBaseUrl()}/subscriptioninfo?id=${userId}`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`
    };
    return axios.get(url,{ headers: headers, ignoreDefaultAuth: true })
  }

  getAllTickets = (userId) => {
    
    const url = `${this.getBaseUrl()}/integrations/freshdesk-integrations/`
    //const url= "https://hummingwavetest2.freshdesk.com/api/tickets"
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/json",
       "Authorization": `Bearer ${accessToken}`
    };
    let request = {
      memberId:userId
    }
    const response= axios.get(url,{params:request, headers: headers, ignoreDefaultAuth: true },)
    console.log(response)
    return response
  }

  getParticularTicketInfo = (id) => {
     const url = `${this.getBaseUrl()}/integrations/freshdesk-integrations/` + id
    //const url= "https://hummingwavetest2.freshdesk.com/api/tickets/102?" 
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
       "Content-Type": "application/json",
       "Authorization": `Bearer ${accessToken}`
      // "Authorization": "Basic OEw2c0gxZ3poSXFJNDkybEY4Ong=",
      // "Accept": "*/*",
      // "Postman-Token": "1d919864-7d64-4ad7-9810-18cb96834f5f",
      // "Host": "hummingwavetest2.freshdesk.com",
      // "Accept-Encoding": "gzip, deflate, br",
      // "Connection": "keep-alive"
    };
    //const request = "include=requester%2Cstats%2Ccompany%2Csurvey%2Cticket_form"
    const response= axios.get(url, {headers: headers, ignoreDefaultAuth: true })
    console.log(response);
    return response
  }


  deleteTicket = (id) => {
     const url = `${this.getBaseUrl()}/integrations/freshdesk-integrations/id=${id}`
   //const url = ` https://hummingwave2.freshdesk.com/api/v2/tickets/${id}`
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`}
    // const headers = {
    //   "Authorization": "Basic M2VuTFg4MFRKNE96RkNQUXU6eA==",
    //   "Accept": "*/*",
    //   "Postman-Token": "1d919864-7d64-4ad7-9810-18cb96834f5f",
    //   "Host": "hummingwavetest2.freshdesk.com",
    //   "Accept-Encoding": "gzip, deflate, br",
    //   "Connection": "keep-alive"
    // };
    return axios.delete(url,{ headers: headers, ignoreDefaultAuth: true })
  }



  updateTicket = (data2,priority) => {
    console.log("id-->",data2)
      const url = `${this.getBaseUrl()}/integrations/freshdesk-integrations/` 
      //const url = ` https://hummingwavetest.freshdesk.com/api/v2/tickets/${data2}`
      const accessToken = sessionStorage.getItem("accessToken");

      const request ={
        priority:priority
      }
      const headers = {
        "Authorization": "Basic UkdJMkVSMGU2dlNaZ054M006WA==",
      "Accept": "*/*",
      "Postman-Token": "1d919864-7d64-4ad7-9810-18cb96834f5f",
      "Host": "hummingwavetest.freshdesk.com",
      "Accept-Encoding": "gzip, deflate, br",
      "Connection": "keep-alive"
      };
      return axios.put(url, request, { headers: headers, ignoreDefaultAuth: true })
    }

    handleDMSignup = (request) => {
      const url = `${this.getBaseUrl()}/dms-member/update`
      return axios.post(url,request)
    }
    
}

const memberService = new MemberService();
export default memberService;
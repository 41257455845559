/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Profile from "views/LoginComponents/Profile.js";
import Register from "views/LoginComponents/Register.js";
import Login from "views/LoginComponents/Login.js";
import SendOTP from "views/LoginComponents/SendOTP.js";
import VehicleJPJDetailsScreen from "views/LoginComponents/VehicleJPJDetailsScreen";
import VehicleOtherDetails from "views/LoginComponents/VehicleOtherDetails";
import MobileLogin from "views/LoginComponents/MobileLogin";
import VehicleConfirmation from "views/LoginComponents/VehicleConfirmation";
import MemberProfile from "views/Profile/Profile";
import ResetPassword from "views/LoginComponents/ResetPassword";
import home from "assets/img/yamahaIcons/home.svg"
import bag from "assets/img/yamahaIcons/bag.svg"
import tag from "assets/img/yamahaIcons/tag.svg"
import shield from "assets/img/yamahaIcons/shield1.svg"

import ticket from "assets/img/yamahaIcons/ticket.svg"
import addUser from "assets/img/yamahaIcons/addUser.svg"
import settings from "assets/img/yamahaIcons/settings.svg"
import service from "assets/img/yamahaIcons/service.svg"
import address from "assets/img/yamahaIcons/address.svg"
import document from "assets/img/yamahaIcons/document.svg"
import Page from "assets/img/yamahaIcons/Page.svg"
import Ticket from "views/freshdesk/Ticket";

import Home from "views/Home/Home";
import EventDetails from "views/Home/EventDetails";
import TransactionHistory from "views/Home/TransactionHistory";
import NotificationDetail from "views/NavbarDetails/NotificationDetail";
import WishlistDetail from "views/NavbarDetails/WishlistDetail";
import MyVouchers from "views/MyVouchers/MyVouchers";
import MyRedemption from "views/MyRedemption/MyRedemption";
import MyProductDetail from "views/MyRedemption/MyProductDetail";
import ManageAddress from "views/Address/ManageAddress";
import BagDetail from "views/NavbarDetails/BagDetail";
import Shop from "views/Shop/Shop";
import BikeService from "views/BikeService/BikeService"
import SettingsDashboard from "views/Settings/SettingsDashboard";
import Membership from "views/Membership/Membership";
import ManageUserDashboard from "views/ManageUser/ManageUserDashboard";
import CheckoutAddress from "views/NavbarDetails/CheckoutAddress";
import OrderConfirmatainScreen from "views/NavbarDetails/OrderConfirmatainScreen";
import PrivacyPolicy from "views/PrivacyPolicy/PrivacyPolicy";
import TermsnConditions from "views/TermsnConditions/Terms";
import { servicesVersion } from "typescript";

import DirectMembershipSignup from "views/DirectMemberSignup/DirectMembershipSignup"

var routes = [
  {
    path: "/home",
    name: "Home",
    icon: home,
    component: Home,
    layout: "/admin",
    showInSideBar:true
  },
  {
    path: "/shop",
    name: "Shop",
    icon: tag,
    component: Shop,
    layout: "/admin",
    showInSideBar:true
  }, 
  {
    path: "/my-vouchers",
    name: "My Vouchers",
    icon: bag,
    component: MyVouchers,
    layout: "/admin",
    showInSideBar:true
  },
  {
    path: "/bike-service",
    name: "E-Service Booking",
    icon: service,
    component: BikeService,
    layout: "/admin",
    showInSideBar:true
  },
  {
    path: "/my-redemptions",
    name: "My Redemption",
    icon: ticket,
    component: MyRedemption,
    layout: "/admin",
    showInSideBar:true
  },
  {
    path: "/product-detail",
    name: "Product Detail",
    icon: ticket,
    component: MyProductDetail,
    layout: "/admin",
    showInSideBar:false
  },
  {
    path: "/transactionHistory",
    name: "Transactions",
    icon: document,
    component: TransactionHistory,
    layout: "/admin",
    showInSideBar:true
    
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: settings ,
    component: NotificationDetail,
    layout: "/admin",
    showInSideBar:false
  }
  ,
  {
    path: "/wishListDetail",
    name: "WishList Detail",
    icon: document,
    component: WishlistDetail,
    layout: "/admin",
    showInSideBar:false
  },
  {
    path: "/bagDetail",
    name: "Bag Detail",
    icon: document,
    component: BagDetail,
    layout: "/admin",
    showInSideBar:false
  },
  {
    path: "/addressDetail",
    name: "Address Detail",
    icon: document,
    component: CheckoutAddress,
    layout: "/admin",
    showInSideBar:false
  },
  {
    path: "/orderConfirmation",
    name: "Order Confirmation",
    icon: document,
    component: OrderConfirmatainScreen,
    layout: "/admin",
    showInSideBar:false
  },
  {
    path: "/membership",
    name: "Membership",
    icon: shield,
    component: Membership,
    layout: "/admin",
    showInSideBar:true
  },{
    path: "/manage-user",
    name: "Manage User",
    icon: addUser,
    component: ManageUserDashboard,
    layout: "/admin",
    showInSideBar:true
  },{
    path: "/address",
    name: "Address",
    icon: address,
    component: ManageAddress,
    layout: "/admin",
    showInSideBar:true
  },
  {
    path: "/settings",
    name: "Settings",
    icon: settings,
    component: SettingsDashboard,
    layout: "/admin",
    showInSideBar:true
  },
  {
    path: "/eventDetails",
    name: "Event Details",
    icon: settings,
    component: EventDetails,
    layout: "/admin",
    showInSideBar:false
  },
  {
    path: "/termsnconditions",
    name: "Terms & Conditions",
    icon: "",
    component: TermsnConditions,
    layout: "/admin",
    showInSideBar:true
  },
  {
    path: "/privacyPolicy",
    name: "Privacy Policy",
    icon: "",
    component: PrivacyPolicy,
    layout: "/admin",
    showInSideBar:true
  },

  // ,
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin",
  //   showInSideBar:true
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    showInSideBar:false
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    showInSideBar:false

  },
  {
    path: "/sendOTP",
    name: "Send OTP",
    icon: "ni ni-circle-08 text-pink",
    component: SendOTP,
    layout: "/auth",
    showInSideBar:false

  },
  {
    path: "/initialDetails",
    name: "Vehicle Initial Details",
    icon: "ni ni-circle-08 text-pink",
    component: VehicleJPJDetailsScreen,
    layout: "/auth",
    showInSideBar:false

  },
  {
    path: "/otherDetails",
    name: "Vehicle Other Details",
    icon: "ni ni-circle-08 text-pink",
    component: VehicleOtherDetails,
    layout: "/auth",
    showInSideBar:false

  },
  {
    path: "/loginViaMobile",
    name: "Login",
    icon: "ni ni-circle-08 text-pink",
    component: MobileLogin,
    layout: "/auth",
    showInSideBar:false

  },
  {
    path: "/vehicleConfirmation",
    name: "Vehicle Confirmation",
    icon: "ni ni-circle-08 text-pink",
    component: VehicleConfirmation,
    layout: "/auth",
    showInSideBar:false

  },
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-circle-08 text-pink",
    component: MemberProfile,
    layout: "/auth",
    showInSideBar:false

  },
  {
    path: "/resetPassword",
    name: "Reset Password",
    icon: "ni ni-circle-08 text-pink",
    component: ResetPassword,
    layout: "/auth",
    showInSideBar:false

  },
  {
    path: "/support",
    name: "Support",
    icon: "",
    component: Ticket,
    layout: "/admin",
    showInSideBar:true
  },
  {
    path: "/",
    name: "DirectMemberSignup",
    icon: "",
    component: DirectMembershipSignup,
    layout: '/signup',
  },

];
export default routes;

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import "../../views/LoginComponents/login.css";
import appGalleryIcon from "../../assets/img/yamahaIcons/appGallery.png";
import appStoreIcon from "../../assets/img/yamahaIcons/appleStore.png";
import playStoreIcon from "../../assets/img/yamahaIcons/googlePlayStore.png";
import memberService from "../service/APIService";
import "./DirectMemberShipSignup.css";

const DirectMembershipSignup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const path = window.location.pathname;
  const encodedNumber = path.split("/").pop(); // Extract the last part after "/"
  const isBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

  // Decode the Base64-encoded string
  const decodedMobileNumber = isBase64(encodedNumber)
    ? atob(encodedNumber)
    : encodedNumber;

  const [data, setData] = useState({
    name: "",
    dob: "",
    mobile: decodedMobileNumber,
  });

  useEffect(() => {
    if (decodedMobileNumber) {
      setData((prevData) => ({
        ...prevData,
        mobile: decodedMobileNumber,
      }));
    }
  }, [decodedMobileNumber]);

  const handleChange = (e) => {
    setError("");
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  // const handleDateChange = (date) => {
  //   setError("");
  //   setData((prevData) => ({
  //     ...prevData,
  //     dob: date ? date.toISOString().split("T")[0] : "",
  //   }));
  // };
  const handleSubmit = () => {
    if (!data.name || !data.dob || !data.mobile) {
      setError(t("Please fill all fields"));
      return;
    }
    const body = {
      memberInfo: {
        name: data.name,
        mobileNumber: data.mobile,
        dob: data.dob,
      },
    };
    setIsLoading(true);
    // Simulate API call
    memberService
      .handleDMSignup(body)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setModalOpen(true);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(t(error.response.data.message));
      })
      .finally((e) => {
        setIsLoading(false);
      });
  };
  const today = new Date();
  const sixteenYearsAgo = new Date();
  sixteenYearsAgo.setFullYear(today.getFullYear() - 16);

  return (
    <>
      <Col lg="5" md="7" className="pb-6 mt-8 mx-auto">
        <Card className="bg-secondary shadow module-border-wrap">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup>
                <label
                  htmlFor="dob"
                  style={{ fontSize: "12px", color: "black" }}
                >
                  {t("Name")}
                </label>
                <InputGroup className="input-group-alternative">
                  <Input
                    name="name"
                    placeholder={t("Name")}
                    type="text"
                    value={data.name}
                    onChange={handleChange}
                    style={{ color: "black" }}
                    className="dms-signup-name-input"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <label
                  htmlFor="dob"
                  style={{ fontSize: "12px", color: "black" }}
                >
                  {t("Date of Birth")}
                </label>
                <InputGroup className="input-group-alternative">
                  <Input
                    name="dob"
                    placeholder={t("Date of Birth")}
                    type="date"
                    pattern="\d{2}/\d{2}/\d{4}"
                    value={data.dob}
                    onChange={handleChange}
                    max={new Date().toISOString().split("T")[0]}
                    style={{ color: "black", cursor: "pointer" }}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <label
                  htmlFor="dob"
                  style={{ fontSize: "12px", color: "#8898aa" }}
                >
                  {t("Mobile number")}
                </label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-mobile-alt"></i>
                      <span style={{ marginLeft: "10px" }}> +60</span>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    style={{ paddingLeft: "10px" }}
                    name="mobile"
                    placeholder={t("Mobile number")}
                    type="number"
                    value={data.mobile}
                    onChange={handleChange}
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-", "."].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    readOnly
                  />
                </InputGroup>
              </FormGroup>

              {error && (
                <Row className="mt-3">
                  <Col xs="12" className="text-center">
                    <small style={{ color: "red" }}>{t(error)}</small>
                  </Col>
                </Row>
              )}

              <div className="text-center">
                {!isLoading && (
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    style={{ width: "75%" }}
                    onClick={handleSubmit}
                  >
                    {t("Proceed")}
                  </Button>
                )}
                {isLoading && <Spinner color="primary" />}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        centered
      >
        <ModalHeader
          toggle={() => setModalOpen(!modalOpen)}
          style={{ height: "0px" }}
        ></ModalHeader>
        <ModalBody style={{ textAlign: "center", paddingTop: "0px" }}>
          <p>
            {t(
              "Thank you for completing the registration for Yamaha E-Services, Warranty and GenBlu Rewards ! We're excited to have you on board to our community. To get the most out of your membership, please download our app and log in with your mobile number. It's the best way to access all the features and benefits we offer."
            )}
          </p>
          <p>
            <div className="success_signup_dialogBox">
              <a
                href="https://play.google.com/store/apps/details?id=com.yamahamotors.genblu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={playStoreIcon}
                  alt="App Gallery"
                  style={{
                    width: "100px",
                    marginBottom: "10px",
                    height: "30px",
                    paddingRight: "3px",
                  }}
                />
              </a>
              <a
                href="https://apps.apple.com/app/genblu-rewards/id1634646726"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appStoreIcon}
                  alt="Apple Store"
                  style={{
                    width: "100px",
                    marginBottom: "10px",
                    height: "30px",
                    paddingRight: "3px",
                  }}
                />
              </a>
              <a
                href="https://appgallery.huawei.com/app/C108354571"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appGalleryIcon}
                  alt="App Gallery"
                  style={{
                    width: "100px",
                    marginBottom: "10px",
                    height: "30px",
                  }}
                />
              </a>
            </div>
          </p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DirectMembershipSignup;

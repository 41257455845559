
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Row, CardText, Nav,
    Col, TabContent, TabPane, NavItem, NavLink, CardTitle,
    Spinner
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import { Redirect, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import React, { useEffect, useState } from 'react';
import memberService from "../service/APIService"
import classnames from 'classnames';
import yamahaVoucherTitle from "assets/img/yamahaIcons/yamahaVoucherTitle.png"
import ticketRed from "assets/img/yamahaIcons/ticketRed.svg"
import ticketGrey from "assets/img/yamahaIcons/ticketGrey.svg"
import QRCode from "react-qr-code";
import AlertDialogSlide from "./AlertDialogSlide";
import moment from "moment"
import VoucherDetails from "./VoucherDetails";
import SubscriptionPopup from 'views/Subscriptions/SubscriptionPopup.js'
import firebase from "utils/firebase";
import { useTranslation } from "react-i18next";

function MyVouchers(props) {
    const [activeTab, setActiveTab] = useState('1');
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const [couponsData, setCouponsData] = useState({})
    const [vouchersData, setVouchersData] = useState({})
    const [activeVoucher, setActiveVoucher] = useState({})
    const [activeVoucherId, setActiveVoucherId] = useState()
    const [activeCoupon, setActiveCoupon] = useState({})
    const [activeCouponId, setActiveCouponId] = useState()
    const [voucherType, setVoucherType] = useState()
    const [couponType, setCouponType] = useState()
    const [showOrder, setShowOrder] = useState(false)
    const [navURL, setNavUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingDetail, setIsLoadingDetail] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [membershipDetails,setMembershipDetails] = useState(JSON.parse(sessionStorage.getItem("membershipDetails")))
    const [isOpen,setIsOpen]= useState(false)
    const [showSubscriptionPopup, setShowSubscriptionPopup] = React.useState(false)
    const toggle = tab => {
        setShowOrder(false)
        
        if (activeTab !== tab) setActiveTab(tab);
       
    }

    const { t } = useTranslation();

    useEffect(() => {
        fetchAllVouchers()
    }, [])
    useEffect(() => {   
        
        
        switch(activeTab){
            case '1' : firebase.logEventForAnalytics("Available Vouchers"); break;
            case '2' : firebase.logEventForAnalytics("Redeemed Vouchers"); break;
            case '3' : firebase.logEventForAnalytics("Expired Vouchers");break;
        
    }
    }, [showDetails,activeTab])

    const fetchAllVouchers = () => {
        setIsLoading(true)
        setShowOrder(false)
        memberService.fetchAllVouchers(userId).then(response => {

            let data = response.data.voucherList.filter(aData =>
                aData.status === "Available"
            )
            setVouchersData(response.data)
            fetchAllCoupons()

        })
    }
    const fetchAllCoupons = () => {
        setIsLoading(true)
        setShowOrder(false)

        memberService.fetchAllCoupons(userId).then(response => {
            let couponsData =  response && response.data && response.data.eServiceCoupons && response.data.eServiceCoupons.filter(aData =>
                aData.status === "Available"
            )
            // if(couponsData && couponsData[0]){
            //     // setShowOrder(true)
            //     setActiveCoupon(couponsData[0])
            //     setActiveCouponId(couponsData[0].id)
            // }
            setCouponsData(response.data)

            setIsLoading(false)

        })
    }
    

    const fetchDetailsForVoucher = () => {
        memberService.fetchDetailsForVoucher(activeVoucherId, userId).then(response => {
            // setActiveVoucher(response.data)
        })
    }
    const fetchDetailsForCoupon = () => {
        memberService.fetchDetailsForCoupon(activeCouponId, userId).then(response => {

        })

    }
    const getFormattedVoucherDate = (transactionTime) => {
        let newTransactionTime = moment(transactionTime).format('DD MMM YYYY')
        return newTransactionTime;
    }

    const getFormattedFullDate = (expiryDate) => {
        let newExpiryDate = moment(expiryDate).format('DD MMM YYYY')
        return newExpiryDate;
    }

    const getFormattedUsageText = (usageStatus) => {
        switch (usageStatus) {
            case "FullyRedeemed":
                return "Fully Redeemed"
            default:
                return
        }
    }

    const handleCouponDetails = (couponType, coupon) => {
        setShowOrder(true)
        setCouponType(couponType)
        setActiveCoupon(coupon)
        setActiveCouponId(coupon.id)
        setActiveVoucher(null)
        setActiveVoucherId("")
        // fetchSpecificCoupon()
    }

    const handleVoucherDetails = (voucherType, voucher) => {
        setIsLoadingDetail(true)
        setActiveCoupon(null)
        setActiveCouponId("")
        memberService.fetchDetailsForVoucher(voucher.id, userId).then(response => {
            // setActiveVoucher(response.data)
            setIsLoadingDetail(false)
            setActiveVoucher(response.data)
            setActiveVoucherId(response.data.id)
            if (response.data.categoryName === 'Genblu') {
                setNavUrl(`https://shoponline.yamaha-motor.com.my/redeem-voucher?memberId=${userId}&voucherCode=${response.data.voucherCode} `)
            }

        })
        setShowOrder(true)
        setVoucherType(voucherType)
        // fetchSpecificVoucher()

    }


    const getFormattedDiscount = (discount) => {
    }

    const openInNewTab = (url) => {
        window.open(url, '_blank').focus();

    }
    const handlePopup =()=>{
        setIsOpen(true);
    }
    const handlePopupCallback =(bool, clickedButton)=>{
        if(clickedButton == 0){
            setShowSubscriptionPopup(true);
        }
        else if(clickedButton == 1){
            //for add bike.
            console.log("Add bike");
            console.log(history.location);
           history.push ({pathname : "/admin/settings", state: {activeSetting : true}});
        }
        setIsOpen(bool);

    }

    const history = useHistory();
    const handelClosePopup = () =>{
        setShowSubscriptionPopup();
    }
    

    return (
        <>
            {showDetails && <VoucherDetails openPopup={showDetails} activeVoucherDescription={activeVoucher}
                closePopup={() => { setShowDetails(false) }} ></VoucherDetails>}
            {isOpen && <AlertDialogSlide isOpen={isOpen} handle={handlePopupCallback}/>}

            { showSubscriptionPopup  &&<SubscriptionPopup openPopup={showSubscriptionPopup}  handelClosePopup = {handelClosePopup}
               membershipDetails={membershipDetails}  closePopup={() => { setShowSubscriptionPopup(false) }}></SubscriptionPopup>} 

            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-white shadow">
                        <CardHeader className="bg-white border-0">
                            <Row>
                                <Col xs="8" className="mt-4">
                                    <Card className="mt-3">
                                        <CardHeader style={{ padding: '0px' }}>
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '1' })}
                                                        onClick={() => { toggle('1'); }}
                                                    >{t("Available")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '2' })}

                                                        onClick={() => { toggle('2'); }}
                                                    >
                                                        {t("Redeemed")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '3' })}
                                                        onClick={() => { toggle('3'); }}
                                                    >
                                                        {t("Expired")}
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </CardHeader>
                                        {isLoading &&
                                            <CardBody className="d-flex justify-content-center">
                                                <Spinner style={{ marginTop: '10px' }} color="primary" /></CardBody>}
                                        {!isLoading && <CardBody style={{ overflow: 'auto' }}>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="1">
                                                    {couponsData && couponsData.eServiceCoupons && couponsData.eServiceCoupons.filter(aData => aData.status === 'Available').length === 0 && vouchersData &&
                                                        vouchersData.voucherList && vouchersData.voucherList.filter(aData => aData.status === 'Available').length === 0 &&
                                                        <Row><Col xs="12" className="d-flex justify-content-center">
                                                            <p className="waterMark" >{t("No vouchers are available to show")}</p></Col></Row>
                                                    }
                                                    {couponsData && couponsData.eServiceCoupons && couponsData.eServiceCoupons.filter(aData => aData.status === 'Available').map((aCoupon, key) => {
                                                        return (<Row key={key} onClick={() => { !(membershipDetails.status == "Activated") ? handlePopup():handleCouponDetails("Available", aCoupon) }}>
                                                            <Col sm="12">
                                                                <div className="wrapper" style={{ cursor: 'pointer' }} >
                                                                    {aCoupon.id !== activeCouponId && <svg class="Subtraction_1_bvq" viewBox="11901.999 -16933 711.804 148.004">
                                                                        <path id="Subtraction_1_bvq" d="M 12605.8046875 -16784.99609375 L 11910.0009765625 -16784.99609375 C 11905.5888671875 -16784.99609375 11901.9990234375 -16788.587890625 11901.9990234375 -16793 L 11901.9990234375 -16836.03515625 C 11906.3974609375 -16837.98828125 11910.13671875 -16841.173828125 11912.8115234375 -16845.248046875 C 11915.552734375 -16849.427734375 11917.0009765625 -16854.30078125 11917.0009765625 -16859.341796875 C 11917.0009765625 -16864.380859375 11915.552734375 -16869.251953125 11912.8115234375 -16873.4296875 C 11910.138671875 -16877.501953125 11906.3994140625 -16880.6875 11901.9990234375 -16882.64453125 L 11901.9990234375 -16924.998046875 C 11901.9990234375 -16929.41015625 11905.5888671875 -16933 11910.0009765625 -16933 L 12605.8046875 -16933 C 12610.21484375 -16933 12613.802734375 -16929.41015625 12613.802734375 -16924.998046875 L 12613.802734375 -16882.642578125 C 12609.404296875 -16880.6875 12605.6669921875 -16877.501953125 12602.994140625 -16873.4296875 C 12600.25390625 -16869.251953125 12598.8056640625 -16864.380859375 12598.8056640625 -16859.341796875 C 12598.8056640625 -16854.30078125 12600.25390625 -16849.427734375 12602.994140625 -16845.251953125 C 12605.666015625 -16841.1796875 12609.404296875 -16837.9921875 12613.802734375 -16836.037109375 L 12613.802734375 -16793 C 12613.802734375 -16788.587890625 12610.21484375 -16784.99609375 12605.8046875 -16784.99609375 Z">
                                                                        </path>
                                                                    </svg>}
                                                                    {aCoupon.id === activeCouponId && <svg class="Subtraction_1_bwa" viewBox="11901.999 -16933 713.609 148.004">
                                                                        <path id="Subtraction_1_bwa" d="M 12607.6064453125 -16784.99609375 L 11910.0009765625 -16784.99609375 C 11905.5888671875 -16784.99609375 11901.9990234375 -16788.587890625 11901.9990234375 -16793 L 11901.9990234375 -16836.03515625 C 11906.3974609375 -16837.98828125 11910.13671875 -16841.173828125 11912.8115234375 -16845.248046875 C 11915.552734375 -16849.427734375 11917.0009765625 -16854.30078125 11917.0009765625 -16859.341796875 C 11917.0009765625 -16864.380859375 11915.552734375 -16869.251953125 11912.8115234375 -16873.4296875 C 11910.138671875 -16877.501953125 11906.3994140625 -16880.6875 11901.9990234375 -16882.64453125 L 11901.9990234375 -16924.998046875 C 11901.9990234375 -16929.41015625 11905.5888671875 -16933 11910.0009765625 -16933 L 12607.6064453125 -16933 C 12612.0185546875 -16933 12615.6083984375 -16929.41015625 12615.6083984375 -16924.998046875 L 12615.6083984375 -16882.64453125 C 12611.2080078125 -16880.6875 12607.4697265625 -16877.501953125 12604.796875 -16873.4296875 C 12602.0556640625 -16869.251953125 12600.607421875 -16864.380859375 12600.607421875 -16859.341796875 C 12600.607421875 -16854.30078125 12602.0556640625 -16849.427734375 12604.796875 -16845.248046875 C 12607.47265625 -16841.173828125 12611.2109375 -16837.98828125 12615.6083984375 -16836.03515625 L 12615.6083984375 -16793 C 12615.6083984375 -16788.587890625 12612.0185546875 -16784.99609375 12607.6064453125 -16784.99609375 Z">
                                                                        </path>
                                                                    </svg>}
                                                                    <img src={yamahaVoucherTitle} height="24.677px" width="99.508px" className=" glyphiconVoucher" style={{ left: '100px' }} />
                                                                    <span className="glyphiconVoucherLabel ">{t("Service Labour Charge Discount Voucher")}</span>
                                                                    <svg className="Path_18607_bv glyphiconVoucherDivider" viewBox="-11734.262 17137 1 114.318">
                                                                        <path id="Path_18607_bv" d="M -11734.26171875 17137 L -11734.26171875 17251.318359375">
                                                                        </path>
                                                                    </svg>                                   
                                                                    <span className="glyphiconVoucherText ">{t("Voucher")}</span>
                                                                    <div className="glyphiconVoucherDiv" style={{ display: 'flex' }}>
                                                                        <p className="glyphiconVoucherImageNo">1</p>
                                                                        <img className="glyphiconVoucherImage " src={ticketRed} alt="" />
                                                                        <span className="glyphiconVoucherPrefix ">{t("E-Voucher")}</span>
                                                                    </div>
                                                                    <span className="glyphiconEServiceExpiryLabel ">{t("Valid Till")}</span>
                                                                    <span style={{ fontSize: 16}} className="glyphiconEServiceExpiryText "> {getFormattedVoucherDate(aCoupon.expiryDate)}</span>
                                                                    <span className="glyphiconEServicePlateNumberLabel ">{t("Plate number")}</span>
                                                                    <span style={{ fontSize: 16}} className="glyphiconEServicePlateNumberText "> {aCoupon.plateNumber}</span>
                                                                </div>

                                                            </Col>
                                                        </Row>)
                                                    })}
                                                    {vouchersData && vouchersData.voucherList && vouchersData.voucherList.filter(aData => aData.status === 'Available').map((aCoupon, key) => {
                                                        {console.log("qq")}
                                                        return (<Row key={key} onClick={() => { !(membershipDetails.status == "Activated") ? handlePopup():  handleVoucherDetails("Available", aCoupon) }}>
                                                            <Col sm="12">
                                                                <div className="wrapper" style={{ cursor: 'pointer' }} >
                                                                    {aCoupon.id !== activeVoucherId && <svg class="Subtraction_1_bv" viewBox="11901.999 -16933 711.804 148.004">
                                                                        <path id="Subtraction_1_bv" d="M 12605.8046875 -16784.99609375 L 11910.0009765625 -16784.99609375 C 11905.5888671875 -16784.99609375 11901.9990234375 -16788.587890625 11901.9990234375 -16793 L 11901.9990234375 -16836.03515625 C 11906.3974609375 -16837.98828125 11910.13671875 -16841.173828125 11912.8115234375 -16845.248046875 C 11915.552734375 -16849.427734375 11917.0009765625 -16854.30078125 11917.0009765625 -16859.341796875 C 11917.0009765625 -16864.380859375 11915.552734375 -16869.251953125 11912.8115234375 -16873.4296875 C 11910.138671875 -16877.501953125 11906.3994140625 -16880.6875 11901.9990234375 -16882.64453125 L 11901.9990234375 -16924.998046875 C 11901.9990234375 -16929.41015625 11905.5888671875 -16933 11910.0009765625 -16933 L 12605.8046875 -16933 C 12610.21484375 -16933 12613.802734375 -16929.41015625 12613.802734375 -16924.998046875 L 12613.802734375 -16882.642578125 C 12609.404296875 -16880.6875 12605.6669921875 -16877.501953125 12602.994140625 -16873.4296875 C 12600.25390625 -16869.251953125 12598.8056640625 -16864.380859375 12598.8056640625 -16859.341796875 C 12598.8056640625 -16854.30078125 12600.25390625 -16849.427734375 12602.994140625 -16845.251953125 C 12605.666015625 -16841.1796875 12609.404296875 -16837.9921875 12613.802734375 -16836.037109375 L 12613.802734375 -16793 C 12613.802734375 -16788.587890625 12610.21484375 -16784.99609375 12605.8046875 -16784.99609375 Z">
                                                                        </path>
                                                                    </svg>}
                                                                    {aCoupon.id === activeVoucherId && <svg class="Subtraction_1_bla" viewBox="11901.999 -16933 713.609 148.004">
                                                                        <path id="Subtraction_1_bla" d="M 12607.6064453125 -16784.99609375 L 11910.0009765625 -16784.99609375 C 11905.5888671875 -16784.99609375 11901.9990234375 -16788.587890625 11901.9990234375 -16793 L 11901.9990234375 -16836.03515625 C 11906.3974609375 -16837.98828125 11910.13671875 -16841.173828125 11912.8115234375 -16845.248046875 C 11915.552734375 -16849.427734375 11917.0009765625 -16854.30078125 11917.0009765625 -16859.341796875 C 11917.0009765625 -16864.380859375 11915.552734375 -16869.251953125 11912.8115234375 -16873.4296875 C 11910.138671875 -16877.501953125 11906.3994140625 -16880.6875 11901.9990234375 -16882.64453125 L 11901.9990234375 -16924.998046875 C 11901.9990234375 -16929.41015625 11905.5888671875 -16933 11910.0009765625 -16933 L 12607.6064453125 -16933 C 12612.0185546875 -16933 12615.6083984375 -16929.41015625 12615.6083984375 -16924.998046875 L 12615.6083984375 -16882.64453125 C 12611.2080078125 -16880.6875 12607.4697265625 -16877.501953125 12604.796875 -16873.4296875 C 12602.0556640625 -16869.251953125 12600.607421875 -16864.380859375 12600.607421875 -16859.341796875 C 12600.607421875 -16854.30078125 12602.0556640625 -16849.427734375 12604.796875 -16845.248046875 C 12607.47265625 -16841.173828125 12611.2109375 -16837.98828125 12615.6083984375 -16836.03515625 L 12615.6083984375 -16793 C 12615.6083984375 -16788.587890625 12612.0185546875 -16784.99609375 12607.6064453125 -16784.99609375 Z">
                                                                        </path>
                                                                    </svg>}

                                                                    <img src={aCoupon.imageFilename} height="118" width="188" className=" glyphiconCoupon" />
                                                                    <svg className="Path_18607_bv glyphiconVoucherDivider" viewBox="-11734.262 17137 1 114.318">
                                                                        <path id="Path_18607_bv" d="M -11734.26171875 17137 L -11734.26171875 17251.318359375">
                                                                        </path>
                                                                    </svg>
                                                                    <span className="glyphiconVoucherText ">{t("Voucher")}</span>
                                                                    <div className="glyphiconVoucherDiv" style={{ display: 'flex' }}>
                                                                        <p className="glyphiconVoucherImgNo">1</p>
                                                                        <img className="glyphiconVoucherImage " src={ticketGrey} alt="" />
                                                                        <span style={{maxWidth: 250 , fontSize: 16}} className="glyphiconVoucherPrefix ">{aCoupon.voucherPrefix}</span>
                                                                    </div>
                                                                    {aCoupon.usageStatus && aCoupon.usageStatus === 'FullyRedeemed' && <span className="glyphiconVoucherUsageText " >{getFormattedUsageText(aCoupon.usageStatus)}</span>}

                                                                    <span className="glyphiconVoucherExpiryLabel ">{t("Valid Till")}</span>
                                                                    <span style={{ fontSize: 16}} className="glyphiconVoucherExpiryText "> {getFormattedVoucherDate(aCoupon.expiryDate)}</span>

                                                                </div>

                                                            </Col>
                                                        </Row>)
                                                    })}
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    {couponsData && couponsData.eServiceCoupons && couponsData.eServiceCoupons.filter(aData => aData.status === 'Redeemed').length === 0 &&
                                                        vouchersData && vouchersData.voucherList && vouchersData.voucherList.filter(aData => aData.status === 'Redeemed').length === 0 &&
                                                        <Row><Col xs="12" className="d-flex justify-content-center">
                                                            <p className="waterMark" >{t("No vouchers are available to show")}</p></Col></Row>
                                                    }
                                                    {couponsData && couponsData.eServiceCoupons && couponsData.eServiceCoupons.filter(aData => aData.status === 'Redeemed').map((aCoupon, key) => {
                                                        return (<Row key={key} onClick={() => { !(membershipDetails.status == "Activated") ? handlePopup(): handleCouponDetails("Redeemed", aCoupon) }}>
                                                            <Col sm="12">
                                                                <div className="wrapper" style={{ cursor: 'pointer' }} >
                                                                    {aCoupon.id !== activeCouponId && <svg class="Subtraction_1_bvq" viewBox="11901.999 -16933 711.804 148.004">
                                                                        <path id="Subtraction_1_bvq" d="M 12605.8046875 -16784.99609375 L 11910.0009765625 -16784.99609375 C 11905.5888671875 -16784.99609375 11901.9990234375 -16788.587890625 11901.9990234375 -16793 L 11901.9990234375 -16836.03515625 C 11906.3974609375 -16837.98828125 11910.13671875 -16841.173828125 11912.8115234375 -16845.248046875 C 11915.552734375 -16849.427734375 11917.0009765625 -16854.30078125 11917.0009765625 -16859.341796875 C 11917.0009765625 -16864.380859375 11915.552734375 -16869.251953125 11912.8115234375 -16873.4296875 C 11910.138671875 -16877.501953125 11906.3994140625 -16880.6875 11901.9990234375 -16882.64453125 L 11901.9990234375 -16924.998046875 C 11901.9990234375 -16929.41015625 11905.5888671875 -16933 11910.0009765625 -16933 L 12605.8046875 -16933 C 12610.21484375 -16933 12613.802734375 -16929.41015625 12613.802734375 -16924.998046875 L 12613.802734375 -16882.642578125 C 12609.404296875 -16880.6875 12605.6669921875 -16877.501953125 12602.994140625 -16873.4296875 C 12600.25390625 -16869.251953125 12598.8056640625 -16864.380859375 12598.8056640625 -16859.341796875 C 12598.8056640625 -16854.30078125 12600.25390625 -16849.427734375 12602.994140625 -16845.251953125 C 12605.666015625 -16841.1796875 12609.404296875 -16837.9921875 12613.802734375 -16836.037109375 L 12613.802734375 -16793 C 12613.802734375 -16788.587890625 12610.21484375 -16784.99609375 12605.8046875 -16784.99609375 Z">
                                                                        </path>
                                                                    </svg>}
                                                                    {aCoupon.id === activeCouponId && <svg class="Subtraction_1_bwa" viewBox="11901.999 -16933 713.609 148.004">
                                                                        <path id="Subtraction_1_bwa" d="M 12607.6064453125 -16784.99609375 L 11910.0009765625 -16784.99609375 C 11905.5888671875 -16784.99609375 11901.9990234375 -16788.587890625 11901.9990234375 -16793 L 11901.9990234375 -16836.03515625 C 11906.3974609375 -16837.98828125 11910.13671875 -16841.173828125 11912.8115234375 -16845.248046875 C 11915.552734375 -16849.427734375 11917.0009765625 -16854.30078125 11917.0009765625 -16859.341796875 C 11917.0009765625 -16864.380859375 11915.552734375 -16869.251953125 11912.8115234375 -16873.4296875 C 11910.138671875 -16877.501953125 11906.3994140625 -16880.6875 11901.9990234375 -16882.64453125 L 11901.9990234375 -16924.998046875 C 11901.9990234375 -16929.41015625 11905.5888671875 -16933 11910.0009765625 -16933 L 12607.6064453125 -16933 C 12612.0185546875 -16933 12615.6083984375 -16929.41015625 12615.6083984375 -16924.998046875 L 12615.6083984375 -16882.64453125 C 12611.2080078125 -16880.6875 12607.4697265625 -16877.501953125 12604.796875 -16873.4296875 C 12602.0556640625 -16869.251953125 12600.607421875 -16864.380859375 12600.607421875 -16859.341796875 C 12600.607421875 -16854.30078125 12602.0556640625 -16849.427734375 12604.796875 -16845.248046875 C 12607.47265625 -16841.173828125 12611.2109375 -16837.98828125 12615.6083984375 -16836.03515625 L 12615.6083984375 -16793 C 12615.6083984375 -16788.587890625 12612.0185546875 -16784.99609375 12607.6064453125 -16784.99609375 Z">
                                                                        </path>
                                                                    </svg>}
                                                                    <img src={yamahaVoucherTitle} height="24.677px" width="99.508px" className=" glyphiconVoucher" style={{ left: '100px' }} />
                                                                    <span className="glyphiconVoucherLabel ">{t("Service Labour Charge Discount Voucher")}</span>
                                                                    <svg className="Path_18607_bv glyphiconVoucherDivider" viewBox="-11734.262 17137 1 114.318">
                                                                        <path id="Path_18607_bv" d="M -11734.26171875 17137 L -11734.26171875 17251.318359375">
                                                                        </path>
                                                                    </svg>
                                                                    <span className="glyphiconVoucherText ">{t("Voucher")}</span>
                                                                    <div className="glyphiconVoucherDiv" style={{ display: 'flex' }}>
                                                                        <p className="glyphiconVoucherImageNo">1</p>
                                                                        <img className="glyphiconVoucherImage " src={ticketRed} alt="" />
                                                                        <span className="glyphiconVoucherPrefix ">{t("E-Voucher")}</span>
                                                                    </div>

                                                                    <span className="glyphiconEServiceExpiryLabel ">{t("Redeemed On")}</span>
                                                                    <span style={{ fontSize: 16}} className="glyphiconEServiceExpiryText "> {getFormattedVoucherDate(aCoupon.redeemedDate)}</span>
                                                                    <span className="glyphiconEServicePlateNumberLabel ">{t("Plate number")}</span>
                                                                    <span style={{ fontSize: 16}} className="glyphiconEServicePlateNumberText "> {aCoupon.plateNumber}</span>

                                                                </div>

                                                            </Col>
                                                        </Row>)
                                                    })}


                                                    {vouchersData && vouchersData.voucherList && vouchersData.voucherList.filter(aData => aData.status === 'Redeemed').map((aCoupon, key) => {
                                                        return (<Row key={key} onClick={() => {!(membershipDetails.status == "Activated") ? handlePopup(): handleVoucherDetails("Redeemed", aCoupon) }}>
                                                            <Col sm="12">
                                                                <div className="wrapper" style={{ cursor: 'pointer' }} >
                                                                    {aCoupon.id !== activeVoucherId && <svg class="Subtraction_1_bv" viewBox="11901.999 -16933 711.804 148.004">
                                                                        <path id="Subtraction_1_bv" d="M 12605.8046875 -16784.99609375 L 11910.0009765625 -16784.99609375 C 11905.5888671875 -16784.99609375 11901.9990234375 -16788.587890625 11901.9990234375 -16793 L 11901.9990234375 -16836.03515625 C 11906.3974609375 -16837.98828125 11910.13671875 -16841.173828125 11912.8115234375 -16845.248046875 C 11915.552734375 -16849.427734375 11917.0009765625 -16854.30078125 11917.0009765625 -16859.341796875 C 11917.0009765625 -16864.380859375 11915.552734375 -16869.251953125 11912.8115234375 -16873.4296875 C 11910.138671875 -16877.501953125 11906.3994140625 -16880.6875 11901.9990234375 -16882.64453125 L 11901.9990234375 -16924.998046875 C 11901.9990234375 -16929.41015625 11905.5888671875 -16933 11910.0009765625 -16933 L 12605.8046875 -16933 C 12610.21484375 -16933 12613.802734375 -16929.41015625 12613.802734375 -16924.998046875 L 12613.802734375 -16882.642578125 C 12609.404296875 -16880.6875 12605.6669921875 -16877.501953125 12602.994140625 -16873.4296875 C 12600.25390625 -16869.251953125 12598.8056640625 -16864.380859375 12598.8056640625 -16859.341796875 C 12598.8056640625 -16854.30078125 12600.25390625 -16849.427734375 12602.994140625 -16845.251953125 C 12605.666015625 -16841.1796875 12609.404296875 -16837.9921875 12613.802734375 -16836.037109375 L 12613.802734375 -16793 C 12613.802734375 -16788.587890625 12610.21484375 -16784.99609375 12605.8046875 -16784.99609375 Z">
                                                                        </path>
                                                                    </svg>}
                                                                    {aCoupon.id === activeVoucherId && <svg class="Subtraction_1_bla" viewBox="11901.999 -16933 713.609 148.004">
                                                                        <path id="Subtraction_1_bla" d="M 12607.6064453125 -16784.99609375 L 11910.0009765625 -16784.99609375 C 11905.5888671875 -16784.99609375 11901.9990234375 -16788.587890625 11901.9990234375 -16793 L 11901.9990234375 -16836.03515625 C 11906.3974609375 -16837.98828125 11910.13671875 -16841.173828125 11912.8115234375 -16845.248046875 C 11915.552734375 -16849.427734375 11917.0009765625 -16854.30078125 11917.0009765625 -16859.341796875 C 11917.0009765625 -16864.380859375 11915.552734375 -16869.251953125 11912.8115234375 -16873.4296875 C 11910.138671875 -16877.501953125 11906.3994140625 -16880.6875 11901.9990234375 -16882.64453125 L 11901.9990234375 -16924.998046875 C 11901.9990234375 -16929.41015625 11905.5888671875 -16933 11910.0009765625 -16933 L 12607.6064453125 -16933 C 12612.0185546875 -16933 12615.6083984375 -16929.41015625 12615.6083984375 -16924.998046875 L 12615.6083984375 -16882.64453125 C 12611.2080078125 -16880.6875 12607.4697265625 -16877.501953125 12604.796875 -16873.4296875 C 12602.0556640625 -16869.251953125 12600.607421875 -16864.380859375 12600.607421875 -16859.341796875 C 12600.607421875 -16854.30078125 12602.0556640625 -16849.427734375 12604.796875 -16845.248046875 C 12607.47265625 -16841.173828125 12611.2109375 -16837.98828125 12615.6083984375 -16836.03515625 L 12615.6083984375 -16793 C 12615.6083984375 -16788.587890625 12612.0185546875 -16784.99609375 12607.6064453125 -16784.99609375 Z">
                                                                        </path>
                                                                    </svg>}
                                                                    <img src={aCoupon.imageFilename} height="118" width="188" className=" glyphiconCoupon" />
                                                                    {/* <span className="glyphiconVoucherLabel ">Yamaha E-Service Voucher</span> */}
                                                                    <svg className="Path_18607_bv glyphiconVoucherDivider" viewBox="-11734.262 17137 1 114.318">
                                                                        <path id="Path_18607_bv" d="M -11734.26171875 17137 L -11734.26171875 17251.318359375">
                                                                        </path>
                                                                    </svg>
                                                                    <span className="glyphiconVoucherText ">{t("Voucher")}</span>
                                                                    <div className="glyphiconVoucherDiv" style={{ display: 'flex' }}>
                                                                        <p className="glyphiconVoucherImgNo">1</p>
                                                                        <img className="glyphiconVoucherImage " src={ticketGrey} alt="" />
                                                                        <span style={{maxWidth: 250 , fontSize: 16}} className="glyphiconVoucherPrefix ">{aCoupon.voucherPrefix}</span>
                                                                    </div>
                                                                    <span className="glyphiconVoucherExpiryLabel ">{t("Redeemed On")}</span>
                                                                    <span style={{ fontSize: 16}} className="glyphiconVoucherExpiryText "> {getFormattedVoucherDate(aCoupon.redeemedOn)}</span>

                                                                </div>

                                                            </Col>
                                                        </Row>)
                                                    })}

                                                </TabPane>
                                                <TabPane tabId="3">
                                                    {couponsData && couponsData.eServiceCoupons && couponsData.eServiceCoupons.filter(aData => aData.status === 'Expired').length === 0
                                                        && vouchersData && vouchersData.voucherList && vouchersData.voucherList.filter(aData => aData.status === 'Expired').length === 0 &&
                                                        <Row><Col xs="12" className="d-flex justify-content-center">
                                                            <p className="waterMark" >{t("No vouchers are available to show")}</p></Col></Row>
                                                    }
                                                    {couponsData && couponsData.eServiceCoupons && couponsData.eServiceCoupons.filter(aData => aData.status === 'Expired').map((aCoupon, key) => {
                                                        return (<Row key={key} onClick={() => { !(membershipDetails.status == "Activated") ? handlePopup(): handleCouponDetails("Expired", aCoupon) }}>
                                                            <Col sm="12">
                                                                <div className="wrapper" style={{ cursor: 'pointer' }} >
                                                                    {aCoupon.id !== activeCouponId && <svg class="Subtraction_1_bvq" viewBox="11901.999 -16933 711.804 148.004">
                                                                        <path id="Subtraction_1_bvq" d="M 12605.8046875 -16784.99609375 L 11910.0009765625 -16784.99609375 C 11905.5888671875 -16784.99609375 11901.9990234375 -16788.587890625 11901.9990234375 -16793 L 11901.9990234375 -16836.03515625 C 11906.3974609375 -16837.98828125 11910.13671875 -16841.173828125 11912.8115234375 -16845.248046875 C 11915.552734375 -16849.427734375 11917.0009765625 -16854.30078125 11917.0009765625 -16859.341796875 C 11917.0009765625 -16864.380859375 11915.552734375 -16869.251953125 11912.8115234375 -16873.4296875 C 11910.138671875 -16877.501953125 11906.3994140625 -16880.6875 11901.9990234375 -16882.64453125 L 11901.9990234375 -16924.998046875 C 11901.9990234375 -16929.41015625 11905.5888671875 -16933 11910.0009765625 -16933 L 12605.8046875 -16933 C 12610.21484375 -16933 12613.802734375 -16929.41015625 12613.802734375 -16924.998046875 L 12613.802734375 -16882.642578125 C 12609.404296875 -16880.6875 12605.6669921875 -16877.501953125 12602.994140625 -16873.4296875 C 12600.25390625 -16869.251953125 12598.8056640625 -16864.380859375 12598.8056640625 -16859.341796875 C 12598.8056640625 -16854.30078125 12600.25390625 -16849.427734375 12602.994140625 -16845.251953125 C 12605.666015625 -16841.1796875 12609.404296875 -16837.9921875 12613.802734375 -16836.037109375 L 12613.802734375 -16793 C 12613.802734375 -16788.587890625 12610.21484375 -16784.99609375 12605.8046875 -16784.99609375 Z">
                                                                        </path>
                                                                    </svg>}
                                                                    {aCoupon.id === activeCouponId && <svg class="Subtraction_1_bwa" viewBox="11901.999 -16933 713.609 148.004">
                                                                        <path id="Subtraction_1_bwa" d="M 12607.6064453125 -16784.99609375 L 11910.0009765625 -16784.99609375 C 11905.5888671875 -16784.99609375 11901.9990234375 -16788.587890625 11901.9990234375 -16793 L 11901.9990234375 -16836.03515625 C 11906.3974609375 -16837.98828125 11910.13671875 -16841.173828125 11912.8115234375 -16845.248046875 C 11915.552734375 -16849.427734375 11917.0009765625 -16854.30078125 11917.0009765625 -16859.341796875 C 11917.0009765625 -16864.380859375 11915.552734375 -16869.251953125 11912.8115234375 -16873.4296875 C 11910.138671875 -16877.501953125 11906.3994140625 -16880.6875 11901.9990234375 -16882.64453125 L 11901.9990234375 -16924.998046875 C 11901.9990234375 -16929.41015625 11905.5888671875 -16933 11910.0009765625 -16933 L 12607.6064453125 -16933 C 12612.0185546875 -16933 12615.6083984375 -16929.41015625 12615.6083984375 -16924.998046875 L 12615.6083984375 -16882.64453125 C 12611.2080078125 -16880.6875 12607.4697265625 -16877.501953125 12604.796875 -16873.4296875 C 12602.0556640625 -16869.251953125 12600.607421875 -16864.380859375 12600.607421875 -16859.341796875 C 12600.607421875 -16854.30078125 12602.0556640625 -16849.427734375 12604.796875 -16845.248046875 C 12607.47265625 -16841.173828125 12611.2109375 -16837.98828125 12615.6083984375 -16836.03515625 L 12615.6083984375 -16793 C 12615.6083984375 -16788.587890625 12612.0185546875 -16784.99609375 12607.6064453125 -16784.99609375 Z">
                                                                        </path>
                                                                    </svg>}
                                                                    <img src={yamahaVoucherTitle} height="24.677px" width="99.508px" className=" glyphiconVoucher" style={{ left: '100px' }} />
                                                                    <span className="glyphiconVoucherLabel ">{t("Service Labour Charge Discount Voucher")}</span>
                                                                    <svg className="Path_18607_bv glyphiconVoucherDivider" viewBox="-11734.262 17137 1 114.318">
                                                                        <path id="Path_18607_bv" d="M -11734.26171875 17137 L -11734.26171875 17251.318359375">
                                                                        </path>
                                                                    </svg>
                                                                    <span className="glyphiconVoucherText ">{t("Voucher")}</span>
                                                                    <div className="glyphiconVoucherDiv" style={{ display: 'flex' }}>
                                                                        <p className="glyphiconVoucherImageNo">1</p>
                                                                        <img className="glyphiconVoucherImage " src={ticketRed} alt="" />
                                                                        <span className="glyphiconVoucherPrefix ">{t("E-Voucher")}</span>
                                                                    </div>

                                                                    <span className="glyphiconEServiceExpiryLabel ">{t("Expired On")}</span>
                                                                    <span style={{ fontSize: 16}} className="glyphiconEServiceExpiryText "> {getFormattedVoucherDate(aCoupon.expiryDate)}</span>
                                                                    <span className="glyphiconEServicePlateNumberLabel ">{t("Plate number")}</span>
                                                                    <span style={{ fontSize: 16}} className="glyphiconEServicePlateNumberText "> {aCoupon.plateNumber}</span>
                                                                </div>

                                                            </Col>
                                                        </Row>)
                                                    })}


                                                    {vouchersData && vouchersData.voucherList && vouchersData.voucherList.filter(aData => aData.status === 'Expired').map((aCoupon, key) => {
                                                        return (<Row key={key} onClick={() => { !(membershipDetails.status == "Activated") ? handlePopup() : handleVoucherDetails("Expired", aCoupon) }}>
                                                            <Col sm="12">
                                                                <div className="wrapper" style={{ cursor: 'pointer' }} >
                                                                    {aCoupon.id !== activeVoucherId && <svg class="Subtraction_1_bv" viewBox="11901.999 -16933 711.804 148.004">
                                                                        <path id="Subtraction_1_bv" d="M 12605.8046875 -16784.99609375 L 11910.0009765625 -16784.99609375 C 11905.5888671875 -16784.99609375 11901.9990234375 -16788.587890625 11901.9990234375 -16793 L 11901.9990234375 -16836.03515625 C 11906.3974609375 -16837.98828125 11910.13671875 -16841.173828125 11912.8115234375 -16845.248046875 C 11915.552734375 -16849.427734375 11917.0009765625 -16854.30078125 11917.0009765625 -16859.341796875 C 11917.0009765625 -16864.380859375 11915.552734375 -16869.251953125 11912.8115234375 -16873.4296875 C 11910.138671875 -16877.501953125 11906.3994140625 -16880.6875 11901.9990234375 -16882.64453125 L 11901.9990234375 -16924.998046875 C 11901.9990234375 -16929.41015625 11905.5888671875 -16933 11910.0009765625 -16933 L 12605.8046875 -16933 C 12610.21484375 -16933 12613.802734375 -16929.41015625 12613.802734375 -16924.998046875 L 12613.802734375 -16882.642578125 C 12609.404296875 -16880.6875 12605.6669921875 -16877.501953125 12602.994140625 -16873.4296875 C 12600.25390625 -16869.251953125 12598.8056640625 -16864.380859375 12598.8056640625 -16859.341796875 C 12598.8056640625 -16854.30078125 12600.25390625 -16849.427734375 12602.994140625 -16845.251953125 C 12605.666015625 -16841.1796875 12609.404296875 -16837.9921875 12613.802734375 -16836.037109375 L 12613.802734375 -16793 C 12613.802734375 -16788.587890625 12610.21484375 -16784.99609375 12605.8046875 -16784.99609375 Z">
                                                                        </path>
                                                                    </svg>}
                                                                    {aCoupon.id === activeVoucherId && <svg class="Subtraction_1_bla" viewBox="11901.999 -16933 713.609 148.004">
                                                                        <path id="Subtraction_1_bla" d="M 12607.6064453125 -16784.99609375 L 11910.0009765625 -16784.99609375 C 11905.5888671875 -16784.99609375 11901.9990234375 -16788.587890625 11901.9990234375 -16793 L 11901.9990234375 -16836.03515625 C 11906.3974609375 -16837.98828125 11910.13671875 -16841.173828125 11912.8115234375 -16845.248046875 C 11915.552734375 -16849.427734375 11917.0009765625 -16854.30078125 11917.0009765625 -16859.341796875 C 11917.0009765625 -16864.380859375 11915.552734375 -16869.251953125 11912.8115234375 -16873.4296875 C 11910.138671875 -16877.501953125 11906.3994140625 -16880.6875 11901.9990234375 -16882.64453125 L 11901.9990234375 -16924.998046875 C 11901.9990234375 -16929.41015625 11905.5888671875 -16933 11910.0009765625 -16933 L 12607.6064453125 -16933 C 12612.0185546875 -16933 12615.6083984375 -16929.41015625 12615.6083984375 -16924.998046875 L 12615.6083984375 -16882.64453125 C 12611.2080078125 -16880.6875 12607.4697265625 -16877.501953125 12604.796875 -16873.4296875 C 12602.0556640625 -16869.251953125 12600.607421875 -16864.380859375 12600.607421875 -16859.341796875 C 12600.607421875 -16854.30078125 12602.0556640625 -16849.427734375 12604.796875 -16845.248046875 C 12607.47265625 -16841.173828125 12611.2109375 -16837.98828125 12615.6083984375 -16836.03515625 L 12615.6083984375 -16793 C 12615.6083984375 -16788.587890625 12612.0185546875 -16784.99609375 12607.6064453125 -16784.99609375 Z">
                                                                        </path>
                                                                    </svg>}

                                                                    <img src={aCoupon.imageFilename} height="118" width="188" className=" glyphiconCoupon" />
                                                                    <svg className="Path_18607_bv glyphiconVoucherDivider" viewBox="-11734.262 17137 1 114.318">
                                                                        <path id="Path_18607_bv" d="M -11734.26171875 17137 L -11734.26171875 17251.318359375">
                                                                        </path>
                                                                    </svg>
                                                                    <span className="glyphiconVoucherText ">{t("Voucher")}</span>
                                                                    <div className="glyphiconVoucherDiv" style={{ display: 'flex' }}>
                                                                        <p className="glyphiconVoucherImgNo">1</p>
                                                                        <img className="glyphiconVoucherImage " src={ticketGrey} alt="" />
                                                                        <span style={{maxWidth: 250 , fontSize: 16}} className="glyphiconVoucherPrefix ">{aCoupon.voucherPrefix}</span>
                                                                    </div>

                                                                    <span className="glyphiconVoucherExpiryLabel ">{t("Expired On")}</span>
                                                                    <span style={{ fontSize: 16}} className="glyphiconVoucherExpiryText "> {getFormattedVoucherDate(aCoupon.expiryDate)}</span>

                                                                </div>


                                                            </Col>
                                                        </Row>)
                                                    })}

                                                </TabPane>
                                            </TabContent>

                                        </CardBody>}
                                    </Card>
                                </Col>
                                <Col xs="4" className="mt-4">
                                    <Card className="mt-3">
                                        {(activeCoupon && activeCoupon.id !== null || activeVoucher && activeVoucher.id !== null) && showOrder && <CardHeader style={{ height: '30px' }}>
                                            <p className="textDescription" style={{ marginTop: '-10px' }}>Details</p>
                                        </CardHeader>}
                                        {activeCoupon && activeCoupon.id !== null && showOrder && <CardBody>

                                            <><Row>
                                                <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img src={yamahaVoucherTitle} height="24.677px" width="99.508px" className=" glyphiconVoucher" style={{ left: '100px' }} />
                                                </Col>
                                            </Row>
                                                <Row>
                                                    <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <span className="glyphiconVoucherLabel ">{t("Service Labour Charge Discount Voucher")}</span>
                                                    </Col>
                                                </Row>
                                                {activeTab === "1" && <Row>
                                                    <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <QRCode value={activeCoupon.voucherCode} />
                                                        {/* <img src={activeVoucher.imageFilename}height="113" width="188"alt=""/> */}
                                                    </Col>
                                                </Row>}
                                                <hr />
                                                <Row>
                                                    <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <p className="textHeading ">{t("Voucher Code")}</p>
                                                        <p className="textDescription">{activeCoupon.voucherCode && activeCoupon.voucherCode.slice(-3)}</p>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <p className="textHeading ">{t("Chassis No.")}</p>
                                                        <p className="textDescription">{activeCoupon.chassisNumber}</p>

                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <p className="textHeading ">{t("Engine No.")}</p>
                                                        <p className="textDescription">{activeCoupon.engineNumber}</p>

                                                    </Col>

                                                </Row>
                                                <Row> <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p className="textHeading ">{t("Model No.")}</p>
                                                    <p className="textDescription">{activeCoupon.modelName}</p>

                                                </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <p className="textHeading ">{t("Plate No.")}</p>
                                                        <p className="textDescription">{activeCoupon.plateNumber}</p>

                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        {activeTab === '1' && <><p className="textHeading ">{t("Awarded On")}n</p>
                                                            <p className="textDescription">{getFormattedFullDate(activeCoupon.rewardedDate)}</p>
                                                        </>}
                                                        {activeTab === '2' && <><p className="textHeading ">{t("Awarded On")}</p>
                                                            <p className="textDescription">{getFormattedFullDate(activeCoupon.rewardedDate)}</p></>}
                                                        {activeTab === '3' && <><p className="textHeading ">{t("Awarded On")}</p>
                                                            <p className="textDescription">{getFormattedFullDate(activeCoupon.rewardedDate)}</p></>}


                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        {activeTab === '1' && <><p className="textHeading ">{t("Valid Till")}</p>
                                                            <p className="textDescription">{getFormattedFullDate(activeCoupon.expiryDate)}</p></>}
                                                        {activeTab === '2' && <><p className="textHeading ">{t("Redeemed On")}</p>
                                                            <p className="textDescription">{getFormattedFullDate(activeCoupon.redeemedDate)}</p></>}
                                                        {activeTab === '3' && <><p className="textHeading ">{t("Expired On")}</p>
                                                            <p className="textDescription">{getFormattedFullDate(activeCoupon.expiryDate)}</p></>}
                                                    </Col>

                                                </Row>


                                            </>
                                        </CardBody>}
                                        {isLoadingDetail && <Spinner style={{ marginTop: '10px' }} color="primary" />}
                                        {activeVoucher && activeVoucher.id !== null && showOrder &&

                                            (!isLoadingDetail && <CardBody>
                                                <><Row>
                                                    <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <p className="textHeading" > {activeVoucher.voucherPrefix}</p>
                                                    </Col>
                                                </Row>

                                                    {activeTab === "1" && <Row>
                                                        <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                            {activeVoucher && activeVoucher.usageStatus !== "FullyRedeemed" && activeVoucher.id && <QRCode value={   activeVoucher.id } />}
                                                            {/* <img src={activeVoucher.imageFilename}height="113" width="188"alt=""/> */}
                                                        </Col>
                                                    </Row>}

                                                    <hr />
                                                    <Row>
                                                        <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p className="textHeading ">{t("Voucher Code")}</p>
                                                            <p className="textDescription">{activeVoucher.voucherPrefix}</p>
                                                        </Col>

                                                    </Row>
                                                    {activeVoucher.voucherType !== "Discount" && <Row>
                                                        <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p className="textHeading ">{t("Voucher Value")}</p>
                                                            <p className="textDescription">RM {parseFloat(activeVoucher.voucherValue).toFixed(2)}</p>

                                                        </Col>

                                                    </Row>}
                                                    {activeVoucher.voucherType === "Discount" && <Row>
                                                        <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p className="textHeading ">{t("Discount")}</p>
                                                            <p className="textDescription">{activeVoucher.percentDeduction} %</p>

                                                        </Col>

                                                    </Row>
                                                    }
                                                    {activeVoucher.voucherType === "Discount" && <Row> <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <p className="textHeading ">{t("Max Value")}</p>
                                                        <p className="textDescription">RM {activeVoucher.amountReductionCeiling}</p>

                                                    </Col>
                                                    </Row>}
                                                    <Row>
                                                        <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p className="textHeading ">{t("Minimum Transaction Value")}</p>
                                                            <p className="textDescription"> RM {parseFloat(activeVoucher.minAmountRequired).toFixed(2)}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p className="textHeading ">{t("Awarded On")}</p>
                                                            <p className="textDescription">{getFormattedFullDate(activeVoucher.awardedOn)}</p>

                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            {activeTab === '1' && <p className="textHeading ">{t("Valid Till")}</p>}
                                                            {activeTab === '1' && <p className="textDescription">{getFormattedFullDate(activeVoucher.validTill)}</p>}

                                                            {activeTab === '2' && <p className="textHeading ">{t("Redeemed On")}</p>}
                                                            {activeTab === '2' && <p className="textDescription">{getFormattedFullDate(activeVoucher.redeemedOn)}</p>}

                                                            {activeTab === '3' && <p className="textHeading ">{t("Expired On")}</p>}
                                                            {activeTab === '3' && <p className="textDescription">{getFormattedFullDate(activeVoucher.validTill)}</p>}


                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} ><p style={{ color: '#138de8', fontSize: '' }} onClick={() => {setShowDetails(true)}}> Read More...</p></Col>
                                                    </Row>


                                                </>
                                                {/* {activeVoucher.voucherType==='Genblu' &&  */}

                                                {activeTab === '1' && (activeVoucher.categoryName === 'Genblu Online Shop' || activeVoucher.categoryName == null) && activeVoucher.usageStatus !== "FullyRedeemed" && <Row>
                                                    <Col xs={12}>
                                                        <a href={navURL} target="_blank">
                                                            <Button style={{ width: '100%', height: '34px', fontSize: '13px', fontWeight: '600', cursor: 'auto' }} color="primary" type="button" onClick={() => openInNewTab(`https://shoponline.yamaha-motor.com.my/redeem-voucher?memberId=${userId}&voucherCode=${activeVoucher.voucherCode}`)}>REDEEM NOW</Button>
                                                        </a>

                                                    </Col>
                                                </Row>}

                                                {/* } */}
                                            </CardBody>)

                                        }

                                    </Card>
                                </Col>

                            </Row>
                        </CardHeader>
                        <CardBody>

                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </>
    );
};


export default MyVouchers;

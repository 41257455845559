/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { RequestInterceptor } from "utils/RequestInterceptor";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import "./i18n";
// import TagManager from 'react-gtm-module'

RequestInterceptor.attachInterceptors();

const token = sessionStorage.getItem("accessToken");
const user = JSON.parse(sessionStorage.getItem("userDetails"));
const profileSetupStatus = sessionStorage.getItem("profileSetupStatus");

const directMembershipSignupStatus = window.location.pathname.includes('/signup');

// TagManager.initialize(tagManagerArgs)
ReactDOM.render(
  <BrowserRouter>
  { !token && directMembershipSignupStatus && <Switch>
      <Route path='/signup'  render={(props) => <AuthLayout {...props} />}/>
      <Redirect from="/" to="/signup" />
      </Switch>
  }
    {!token && !directMembershipSignupStatus && <Switch>
      <Route path='/auth'  render={(props) => <AuthLayout {...props} />}/>
      <Redirect from="/" to="/auth/login" />
      </Switch>}
      {token  && !directMembershipSignupStatus && profileSetupStatus!=="Completed" &&<Switch>
      <Route path='/auth'  render={(props) => <AuthLayout {...props} />}/>
      <Redirect from="/" to="/auth/login" />
      </Switch>}
      {token && !directMembershipSignupStatus && profileSetupStatus==="Completed" &&<Switch>
      <Route path='/admin'  render={(props) => <AdminLayout {...props} />}/>
      <Redirect from="/" to="/admin/home" />
      </Switch>}
    <ToastsContainer store={ToastsStore} />
  </BrowserRouter>,
  document.getElementById("root")
);

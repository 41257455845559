import React from "react";
import { useLocation, Route, Switch, Redirect ,Link} from "react-router-dom";
// reactstrap components
import { Container, Row ,NavbarBrand} from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";
import { useTranslation } from "react-i18next";


// import image from "assets/img/yamahaIcons/"
const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const {i18n} = useTranslation();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {

        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } 
      else if(prop.layout === "/signup"){
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      else {
        return null;
      }
    });
  };

  return (
    <>
    <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
      {/* Header Placeholder */}
      <div className="header" />

      {/* Main Content */}
      <div className="main-content imageBg" ref={mainContent}>
        <AuthNavbar />

        {/* Language Selector at the top-right corner */}
        <div className="language-selector">
          <button
            style={{
              color: i18n.language === 'ma' ? 'black' : 'white',
              backgroundColor: i18n.language === 'ma' ? 'white' : '#1da1f2',
              padding: '5px',
              border: '1px solid black',
            }}
            onClick={() => i18n.changeLanguage('en')}
          >
            English
          </button>
          <button
            style={{
              color: i18n.language === 'ma' ? 'white' : 'black',
              backgroundColor: i18n.language === 'ma' ? '#1da1f2' : 'white',
              padding: '5px',
              border: '1px solid black',
            }}
            onClick={() => i18n.changeLanguage('ma')}
          >
            Malay
          </button>
        </div>

        {/* Page Content */}
        <Container className="mt--3 pb-8">
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Row>
        </Container>
      </div>

      {/* Footer */}
      <AuthFooter />
    </div>

    {/* Styles */}
    <style jsx>{`
      .main-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .language-selector {
        display: flex;
        justify-content: flex-end;
        padding: 20px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000;
      }

      button {
        margin-left: 5px;
      }

      @media (max-width: 768px) {
        .language-selector {
          padding: 5px;
        }

        button {
          font-size: 12px;
          padding: 3px 5px;
        }
      }
        .main-content {
          height: 100vh;
          display: flex;
         justify-content: center;
        }
      
    `}</style>
  </>
  
  );
};

export default Auth;
